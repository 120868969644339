import React from "react"
import getNewsletterSubscriptionUrl from "@src/utils/getNewsletterSubscriptionUrl"

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const STATES = {
  NOT_LOADING: "NOT_LOADING",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
}
export default function useNewsletterForm(location, param) {
  const [errors, setErrors] = React.useState([""])
  const [loading, setLoading] = React.useState(STATES.NOT_LOADING)
  const [form, set] = React.useState({ email: "" })

  const validate = React.useCallback(() => {
    const validationErrors = ["", ""]
    if (!form.email) {
      validationErrors[0] = "Email is required"
    } else if (!EMAIL_REGEX.test(form.email)) {
      validationErrors[0] = "Please enter a valid email"
    }

    setErrors(validationErrors)
    if (validationErrors[0]) return false
    return true
  }, [form.email])

  const setForm = React.useCallback(
    key => e => {
      e.persist()
      if (key === "email") setErrors(() => [""])
      set(form => ({ ...form, [key]: e.target.value }))
    },
    []
  )

  const submit = React.useCallback(
    e => {
      e.preventDefault()
      if (!validate()) return
      setLoading(STATES.LOADING)
      fetch(getNewsletterSubscriptionUrl(location), {
        headers: { "content-type": "application/json" },
        method: "POST",
        body: JSON.stringify({ email: form.email, ...(param && { param }) }),
      })
        .then(response => response.json())
        .then(setLoading(STATES.SUCCESS))
    },
    [validate, location, form.email]
  )

  return {
    form,
    setForm,
    submit,
    loading,
    errors,
  }
}
