import React from "react"
import Link from "gatsby-plugin-transition-link"

import LinkedinIcon from "@src/icons/footer/linkedin.svg"
import TwitterIcon from "@src/icons/footer/twitter.svg"
import DiscordIcon from "@src/icons/footer/discord.svg"
import MediumIcon from "@src/icons/footer/medium.svg"
import YoutubeIcon from "@src/icons/footer/youtube.svg"
import useNewsletterForm from "@src/hooks/useNewsletterForm"
import { HELLO_EMAIL_ID, DISCORD_INVITE } from "@src/const"
import { TESTS } from "@src/data/test"
import useTest from "@src/hooks/useTest"

import * as styles from "./Footer.module.scss"

const Footer = ({ className = "" }) => {
  const { form, setForm, submit, loading } = useNewsletterForm()
  const { weightIndex, trackTest } = useTest(TESTS.BUILD_WITH_US)
  const activeClassName = loading === "SUCCESS" ? styles.active : ""

  const addSpace = (
    <>
      <br /> <br /> <br /> <br /> <br />
    </>
  )

  return (
    <footer className={`${styles.footer} ${className}`}>
      <div className={styles.footerWrapper}>
        <div className={styles.footerNewsletter}>
          <div className={styles.footerNewsletterColumn}>
            <h3 className={styles.footerNewsletterTitle}>
              Stay updated, keep in sync.{" "}
            </h3>
            <p className={styles.footerNewsletterSubtitle}>
              Register for full access to our product & community highlights.
              Get new feature updates, participate in product roadmap planning,
              stay ahead of the curve.
            </p>
          </div>
          <div className={styles.footerNewsletterColumn}>
            <input
              placeholder="Write your email id"
              className={styles.footerNewsletterInput}
              type="text"
              for="email"
              value={form.email}
              onChange={setForm("email")}
            />
            <button onClick={submit} className={styles.footerNewsletterButton}>
              Subscribe
            </button>

            <div
              className={`${styles.footerNewsletterSuccess} ${activeClassName}`}
            >
              ✅ You've subscribed to the newsletter successfully!
            </div>
          </div>
        </div>
        <div className={styles.footerNav}>
          <FooterColumn title="Platform">
            <FooterLink href="https://canonic.instatus.com">
              System Status
            </FooterLink>
            <FooterLink href="/pricing">Pricing</FooterLink>
            {addSpace}
          </FooterColumn>
          <FooterColumn title="Resources">
            <FooterLink href="/docs">Documentation</FooterLink>
            <FooterLink href={DISCORD_INVITE}>Community</FooterLink>
            <FooterLink href="/blog">Blog</FooterLink>
          </FooterColumn>
          <FooterColumn title="Company">
            {/* <FooterLink href="/investors">Investors</FooterLink> */}
            <FooterLink href="/privacy-policy">Privacy Policy</FooterLink>
            <FooterLink href="/terms-of-service">Terms of Service</FooterLink>
            <FooterLink href="/affiliate-program">Affiliate Program</FooterLink>
            <FooterLink href="/partner-program">Partner Program</FooterLink>
          </FooterColumn>

          <FooterColumn title="Contact Us">
            <p>
              To discuss ideas write to:
              <br />
              <strong>
                <a
                  href={`mailto:${HELLO_EMAIL_ID}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {HELLO_EMAIL_ID}
                </a>
              </strong>
            </p>
            <p>
              For support requests:
              <br />
              <strong>
                <a
                  href="mailto:support@canonic.dev"
                  target="_blank"
                  rel="noreferrer"
                >
                  support@canonic.dev
                </a>
              </strong>
            </p>
            <a
              className={`${styles.footerGetADemo} ${styles.filled}`}
              href={weightIndex === "0" ? "/get-a-demo" : "/build-with-us"}
              onClick={() =>
                trackTest(
                  TESTS.BUILD_WITH_US.tests[weightIndex].name,
                  TESTS.BUILD_WITH_US.key,
                  "Footer"
                )
              }
            >
              {weightIndex === "0" ? "Get a Demo" : "Build with Us"}
            </a>
            <br />
            <br />
          </FooterColumn>
        </div>
        <div className={`${styles.footerRow} ${styles.footerRowLast}`}>
          <div className={styles.footerRowLogo}>Canonic</div>
          <div className={styles.footerRowSocial}>
            <a
              className={styles.footerRowSocialLogo}
              href="https://www.linkedin.com/company/canonichq"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.footerRowSocialLogoImage}
                alt="linkedin"
                src={LinkedinIcon}
              />
            </a>
            <a
              className={styles.footerRowSocialLogo}
              href="https://twitter.com/CanonicHQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.footerRowSocialLogoImage}
                alt="twitter"
                src={TwitterIcon}
              />
            </a>
            <a
              className={styles.footerRowSocialLogo}
              href={DISCORD_INVITE}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.footerRowSocialLogoImage}
                alt="discord"
                src={DiscordIcon}
              />
            </a>
            <a
              className={styles.footerRowSocialLogo}
              href={"https://www.youtube.com/@canonichq"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.footerRowSocialLogoImage}
                alt="youtube"
                src={YoutubeIcon}
              />
            </a>
            <a
              className={styles.footerRowSocialLogo}
              href="https://medium.com/@canonicHQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.footerRowSocialLogoImage}
                alt="medium"
                src={MediumIcon}
              />
            </a>
          </div>
          <div className={styles.footerRowCopyright}>
            © {new Date().getFullYear()} — All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  )
}

const FooterColumn = ({ title, children, className }) => {
  return (
    <div className={`${styles.footerColumn} ${className}`}>
      <div className={styles.footerTitle}>{title}</div>
      <ul className={styles.footerList}>{children}</ul>
    </div>
  )
}

const FooterLink = ({ children, href }) => {
  if (href.includes("http://") || href.includes("https://")) {
    return (
      <>
        <a
          className={styles.footerLink}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
        <br />
      </>
    )
  }
  return (
    <>
      <Link
        activeClassName={styles.active}
        className={styles.footerLink}
        to={href}
        entry={{ length: 0.3 }}
        exit={{ length: 0.3 }}
      >
        {children}
      </Link>
      <br />
    </>
  )
}

export default Footer
