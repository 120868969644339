// extracted by mini-css-extract-plugin
export var active = "Footer-module--active--3HtGf";
export var filled = "Footer-module--filled--3HwRw";
export var footer = "Footer-module--footer--3mBiF";
export var footerColumn = "Footer-module--footerColumn--31l50";
export var footerGetADemo = "Footer-module--footerGetADemo--3lSOv";
export var footerLink = "Footer-module--footerLink--2Ue1k";
export var footerList = "Footer-module--footerList--4Mazr";
export var footerNav = "Footer-module--footerNav--3PGhr";
export var footerNavIndustry = "Footer-module--footerNavIndustry--1XsrC";
export var footerNavSubColumn = "Footer-module--footerNavSubColumn--eZrPc";
export var footerNewsletter = "Footer-module--footerNewsletter--1grZl";
export var footerNewsletterButton = "Footer-module--footerNewsletterButton--aozHw";
export var footerNewsletterColumn = "Footer-module--footerNewsletterColumn--2WUMY";
export var footerNewsletterInput = "Footer-module--footerNewsletterInput--1WLQl";
export var footerNewsletterSubtitle = "Footer-module--footerNewsletterSubtitle--3RPkm";
export var footerNewsletterSuccess = "Footer-module--footerNewsletterSuccess--2qQsk";
export var footerNewsletterTitle = "Footer-module--footerNewsletterTitle--1moQv";
export var footerRow = "Footer-module--footerRow--1Jyfx";
export var footerRowCopyright = "Footer-module--footerRowCopyright--3zDtL";
export var footerRowLast = "Footer-module--footerRowLast--1NZnD";
export var footerRowLogo = "Footer-module--footerRowLogo--2OQkF";
export var footerRowSocial = "Footer-module--footerRowSocial--3RuUR";
export var footerRowSocialLogo = "Footer-module--footerRowSocialLogo--10Pqk";
export var footerTitle = "Footer-module--footerTitle--aN0TY";
export var footerWrapper = "Footer-module--footerWrapper--3HhMw";
export var showIn = "Footer-module--show-in--32fl4";