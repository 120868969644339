import React from "react"
import { TransitionState } from "gatsby-plugin-transition-link"

import Footer from "../Footer"
import SEO from "../seo"
import * as styles from "./Layout.module.scss"

export default function Layout({
  children,
  title,
  description,
  className = "",
  url,
  ogImage = "",
  ogType,
  ogTitle,
}) {
  return (
    <TransitionState>
      {({ transitionStatus }) => (
        <div
          className={`${styles.layout} ${className} ${styles[transitionStatus]}`}
        >
          <SEO
            title={title}
            description={description}
            lang="en-US"
            url={url}
            ogImage={appendURLIfNeeded(ogImage)}
            ogType={ogType}
            ogTitle={ogTitle}
          />
          {children}
          <Footer />
        </div>
      )}
    </TransitionState>
  )
}

function appendURLIfNeeded(ogImage) {
  const baseURL =
    process.env.NODE_ENV === "development"
      ? ""
      : process.env.NODE_ENV === "staging"
      ? "https://staging.canonic.dev"
      : "https://canonic.dev"

  if (ogImage.startsWith("https://canonic.dev/")) {
    return ogImage
  } else if (ogImage.startsWith("https://")) {
    const path = ogImage.substr("https://".length)
    return `${baseURL}${path}`
  } else if (ogImage.startsWith("/static/")) {
    return `${baseURL}${ogImage}`
  }
  return ogImage
}
