import React from "react"

import Layout from "@src/components/Layout"

import * as styles from "./PrivacyPolicy.module.scss"

const PrivacyPolicy = ({ location }) => {
  return (
    <Layout
      title="Privacy Policy"
      description="Canonic's Privacy Policy"
      url="https://canonic.dev/privacy-policy"
      location={location}
      switchOnId={null}
    >
      <div className={styles.privacyPolicy}>
        <header>
          <h1>Canonic Privacy and Data Protection Policy</h1>
        </header>
        <div>
          <h2>I. OBJECTIVE, SCOPE AND APPLICABILITY</h2>
          <ol>
            <li>
              Canonic Inc and its affiliates worldwide including Canonic Private
              Limited (hereinafter collectively referred to as the “
              <b>Company</b>”, “<b>We</b>”, “<b>Us</b>”, “<b>Our</b>”) are
              dedicated to protect and respect the individual’s legal rights of
              privacy and data protection while collecting, storing, using and
              transmitting the Personal Data (as defined below).
            </li>
            <li>
              Unless a separate privacy policy is provided to You for any
              specific product or service of the Company, this Privacy and Data
              Protection Policy (“<b>Policy</b>”) explains Our privacy and data
              protection practices vis-à-vis how We collect, use, process,
              disclose and transfer the Personal Data, through the website
              canonic.dev.com (“<b>Website</b>”) and mobile applications
              (collectively, the “<b>Platform</b>”) of the individuals who
              browse, access or use the Platform or provide information on or
              through the Platform (hereinafter, collectively referred to as “
              <b>You</b>”, “<b>Your</b>”) in connection with Our services,
              described more particularly in the terms of use and other policies
              as may be available on the Platform (collectively the “
              <b>Terms of Use</b>”) (such services being hereinafter referred to
              as “<b>Services</b>”). This Policy will inform You about Your
              rights and the choices You may have with respect to Your Personal
              Data, and how You can contact Us about Our privacy and data
              protection practices. We are committed to respect Your Personal
              Data, online privacy and ensuring that the Personal Data is
              processed for Our legitimate business purposes and in a
              transparent manner, as set out herein.
            </li>
            <li>
              <b>
                THIS POLICY SHOULD BE READ IN CONJUNCTION AND TOGETHER WITH THE
                TERMS OF USE AND IF YOU DO NOT AGREE WITH THE TERMS OF USE OR
                THE POLICY, PLEASE DO NOT USE/ACCESS THE PLATFORM OR ANY OTHER
                COMPANY’S PRODUCT OR PROCEED BEYOND THE PRIVACY POLICY SCREEN.
                IF YOU CONTINUE TO USE THE PLATFORM, OR ANY OTHER COMPANY’S
                PRODUCT, WE UNDERSTAND THAT YOU ARE AWARE OF THIS POLICY AND ITS
                CONTENT, AND HAVE PROVIDED YOUR CONSENT ON SUCH BASIS.
              </b>
            </li>
            <li>
              Further, We may engage third parties who will be working with or
              for the Company, and who may have the access to the Personal Data
              to perform their functions, but they may not use it for any other
              purposes. The Company may act as a data processor or a data
              controller depending on the Service being provided and the amount
              of control the Company has over the purpose(s) and means of the
              data processing.
            </li>
            <li>
              Please note, that any Personal Data provided to the Company by Our
              clients or any other third party is governed by their respective
              privacy and data protection policies. This Policy does not govern
              any other information or communications that may have been
              collected by such parties. The Company only accesses such Personal
              Data on request of the client or the owner of such Personal Data.
            </li>
            <li>
              You agree that the Company reserves the right to take any legal or
              other action against You including the right to deny the usage
              rights to the Platform and the Services and referral to the
              appropriate authorities.
            </li>
            <li>
              For the purpose of this Policy, the term “Personal Data” refers to
              any information relating to an identified or identifiable
              individual and includes the information that identifies, relates
              to, describes, is reasonably capable of being associated with, or
              could reasonably be linked, directly or indirectly, with a
              particular consumer or household.
            </li>
          </ol>
          <h2>II. TYPES OF THE PERSONAL DATA COLLECTED</h2>
          <p>
            We collect certain data including Personal Data about You, depending
            upon the Services You avail and the manner You interact with Us.
            Further, we also collect certain data from third parties which may
            include Your Personal Data and such Personal Data is also governed
            by this Policy. For providing the Services to You, We may collect
            from the following categories of the data :
          </p>
          <ol>
            <li>
              <u>Contact Information:</u> We may collect contact information
              such as name, email address, resident country, phone number,
              designation (if under an employment), educational qualifications,
              technical knowledge related to information technology etc.
            </li>
            <li>
              <u>Unique identifiers:</u> We may collect information such as
              customer phone number, account number, subscription number, system
              identifiers (including username or online credentials), device
              advertisers, advertising IDs, and IP address.
            </li>
            <li>
              <u>Government-issued identification information numbers:</u> We
              may collect information such as social security number, driver’s
              license number, aadhaar number and passport number. Further, we
              may also collect any Personal Data that would be required by the
              Company to ensure compliance with any applicable laws.
            </li>
            <li>
              <u>Transaction and Commercial Information:</u> We may collect
              information such as customer account information, qualification
              data, number of entries made in the database, recording of
              customer sessions, any files or images uploaded or created using
              the Platform, configuration information of any project created
              using the Platform, number of application programming interface
              call, purchase history and related records (returns, product
              service records, records of payments, credits etc.), records
              related to downloads and purchases of products and applications,
              non-biometric data collected for consumer authentication
              (passwords, account security questions), customer service records,
              reviews and suggestions shared by the customer.
            </li>
            <li>
              <u>
                Inferred Information derived from other information listed in
                this section:
              </u>
              We create inferred and derived data elements by analysing Our
              relationship and transactional information.
            </li>
            <li>
              <u>
                Online and Technical Information, including internet or other
                electronic network activity information:
              </u>{" "}
              This category includes data such as IP address, MAC address, SSIDs
              or other device identifiers or persistent identifiers, online user
              ID, encrypted password, device characteristics (such as browser
              information), web server logs, application logs, browsing data,
              viewing data, website and app usage, cookies, web beacons, clear
              gifs and pixel tags.
            </li>
            <li>
              <u>Other Information:</u> We may collect Your information such as
              name, contact details, preferences, etc. through surveys and
              forms, when You choose to participate in these surveys etc. When
              You communicate with or use the Platform to communicate with other
              users (such as partners) on our platform, We collect information
              about Your communication and any information You choose to
              provide.
            </li>
            <li>
              <u>Cookies:</u> When You visit the Platform, We use cookies to
              automatically collect, store and use technical information about
              Your system and interaction with Our Platform.
            </li>
            <li>
              <u>Permissible information:</u> To the extent permitted by law,
              the Company may record and monitor Your communications with Us to
              ensure compliance with our legal and regulatory obligations and
              our internal policies. This may include communications through
              telephone, by email, via the contact form, via a link contained in
              an email sent by Us or via some other electronic message offered
              to You, via the chat function, web care, surveys or (panel)
              surveys, the (mobile) website, if You sign up for newsletters or
              via social media. This may also include recording of telephone
              conversations.
            </li>
            <li>
              <u>Collection of anonymized data:</u> We may also collect and/or
              generate anonymized and aggregated information from Your use of
              the Platform. The anonymized or aggregated information is not
              Personal Data since We are not able to re-identify You using any
              means available to Us from that anonymized or aggregated
              information. The anonymized and aggregated information is used for
              a variety of functions, including to help Us identify and
              remediate any bugs, and to improve the performance of Our
              Platform. We may share this information with third parties for Our
              or their purposes in an anonymized or aggregated form that is
              designed to prevent anyone from identifying You.
            </li>
          </ol>
          <h2>III. WHY WE COLLECT PERSONAL DATA</h2>
          <p>
            1. We collect Your Personal Data for providing Services or for any
            other request raised by You. Below is the list of purposes for which
            We may process Your Personal Data:
          </p>
          <ul type="a">
            <li>Creation of Your account, sending any agreements,</li>
            <li>For providing You the Services;</li>
            <li>
              Facilitation of payment, in connection with chargeable Services;
            </li>
            <li>Automated processing in order to provide Our Services;</li>
            <li>
              Handling of complaints, questions and disputes with respect to the
              Terms of Use, the Services or this Policy;
            </li>
            <li>To detect, prevent and address any technical issues;</li>
            <li>To monitor usage of Our Services;</li>
            <li>To communicate the updates made on the Platform;</li>
            <li>
              Acceptance and issuing of offers to current and potential clients
              and performance of contracts with the Company.
            </li>
          </ul>
          <p>
            2. Further, We may also use the Personal Data for the purpose of the
            following
          </p>
          <ul type="a">
            <li>
              Analytics, servicing, understanding the ways and manners in which
              We can improve Our Services and users’ experience and the quality
              of Our Services and Platform;
            </li>
            <li>
              Communicating marketing or promotional information about Our
              Platform and Services, which could be done directly, but also for
              instance via social media campaigns on Facebook, for example,
              and/or search engines like Google;
            </li>
            <li>
              Preventing, detecting, investigating and taking action against
              crimes, any other illegal activities, suspected fraud, or
              violations of Company’s Terms of Use in any jurisdiction;
            </li>
            <li>
              Establishing, exercising or defending legal rights in connection
              with legal proceedings and seeking professional or legal advice in
              relation to such legal proceedings;
            </li>
            <li>
              Complying with any applicable law, regulation, legal process or
              enforceable governmental request.
            </li>
          </ul>
          <p>
            3. We collect the Personal Data to provide the Services requested by
            You or to fulfill Your request. Where additional, optional
            information is sought, You will be notified of such requirement at
            the point of collection. Further, We process the Personal Data, so
            long as We have a ground under the law to do so. Accordingly, We
            process the Personal Data based on one of the following conditions:
          </p>
          <ul>
            <li>
              <u>Performance of a contract:</u>Herein, We process the Personal
              Data as it is necessary in order to fulfill Our obligations set
              forth under a contract or for the performance of Our agreement
              with You;
            </li>
            <li>
              <u>Legal obligation</u>Herein, We process the Personal Data in
              order to comply with a legal obligation, such as keeping records
              for tax purposes or providing information to a public body or law
              enforcement agency;
            </li>
            <li>
              <u>Legitimate interests:</u>Herein, We process Your Personal Data
              information where it is in Our legitimate interest in running a
              lawful business. We will only rely on such a ground where an
              assessment has been performed balancing the interests and rights
              involved and the necessity of the processing in order to provide
              our Services, products and features to You. Few examples of Our
              legitimate interests are: (i) to offer information and/or Services
              to individuals who visit the Platform; or (ii) to prevent fraud or
              criminal activity and to safeguard our IT systems etc.;
            </li>
            <li>
              <u>Your consent:</u> In some cases, We will seek Your specific
              permission to process Your Personal Data and We will only process
              Your Personal Data in this way if You agree for Us doing so. You
              may withdraw Your consent at any time by requesting Us at{" "}
              <u>
                <a href="mailto: support@canonic.dev">support@canonic.dev</a>
              </u>
            </li>
          </ul>
          <p>
            4. To the extent permissible under the applicable law, We may use
            and disclose any non-personal data (data which does not contain any
            information that can be used to identify natural person) for any
            purpose. However, in the event We combine any non-personal data with
            Personal Data, then We will only use and disclose such combined
            information as per this Policy.
          </p>

          <h2>IV. RENTENTION AND STORAGE OF THE PERSONAL DATA</h2>
          <ol>
            <li>The Personal Data will be retained as under:</li>
            <li>
              <b>Personal Data We retain until You delete it:</b> Some data can
              be deleted anytime by You, such as the content that You create or
              upload on Our Platform. Our Platform allows You to correct or
              delete the Personal Data stored in Your account. For example, if
              You wish to delete Your Personal Data, You should submit a delete
              request at{" "}
              <u>
                <a href="mailto: support@canonic.dev">support@canonic.dev</a>
              </u>
              or change the details of Your account. When You delete any
              Personal Data, We follow a deletion process to make sure that Your
              Personal Data is safely and completely removed from Our servers or
              retained only in anonymized form, if required, as explained below.
              Please note, We might take upto 3 (three) months to delete the
              data from the date of delete request. We try to ensure that Our
              Services protect information from accidental or malicious
              deletion. Because of this, there may be delays between when You
              delete something and when copies are deleted from Our active and
              backup systems. Further, Your Personal Data is stored with us for
              a period of 2 (two) years if You have not requested for deletion
              but You have deleted Your Account.
            </li>
            <li>
              <b>Information retained until Your Account is deleted:</b> We
              retain some of the Personal Data for the life of Your Account if
              it’s required by Us to provide You the Services or is useful for
              helping Us understand how Users interact with Our features and how
              We can improve our Services.
            </li>
            <li>
              <b>
                Personal Data that expires or is anonymized after a specific
                period of time:
              </b>{" "}
              In some cases, We store certain Personal Data for a predetermined
              period of time. For each type of data, We set retention timeframes
              based on the purpose of its collection. For example, the Personal
              Data collected for the purpose of providing the Services is stored
              and retained for a period of 10 (ten) years post the completion of
              the Services. We also take steps to anonymize certain data post
              the expiry of the set time periods.
            </li>
            <li>
              <b>
                Information retained for extended time periods for limited
                purposes:
              </b>{" "}
              Please note that the Personal Data sometimes may be held for
              longer periods where extended retention periods are required by
              local law, regulation, or professional standards and to establish,
              exercise or defend our legal rights. Sometimes business
              requirements also oblige Us to retain certain information, for
              specific purposes, for an extended period of time. For example,
              when You make a payment to Us, We’ll retain such data for longer
              periods of time as required for tax or accounting purposes.
            </li>
            <li>
              We may store Your Personal Data using Our own secure on-site
              servers or other internally hosted technology. Your Personal Data
              may also be stored by third parties, via cloud services or other
              technology, with whom the Company has contracted, to support the
              Company's business operations, such as data centers, domain
              administrators, cloud service providers, etc. These third parties
              do not use or have access to Your Personal Data other than for
              cloud storage and retrieval. When We share information with
              others, We put contractual arrangements and security mechanisms in
              place as appropriate to protect the information and to comply with
              our information protection, confidentiality and security
              standards.
            </li>
            <li>
              We may share non-personally identifiable information publicly and
              with Our partners such as publishers, advertisers, developers, or
              rights holders. For example, We share information publicly to show
              trends about the general use of Our Services
            </li>
          </ol>
          <h2>V. SHARING OF THE PERSONAL DATA</h2>
          <ol>
            <li>
              Save and otherwise provided in this Policy, We do not rent, share,
              list or sell the Personal Data with any third parties, except as
              necessary for our legitimate professional and business needs, to
              carry out Your requests, and/or as required or permitted by an
              applicable law. The Personal Data may be shared with the third
              parties only if is for the purposes identified above or in the
              event You consent for the sharing of such Personal Data. We always
              endeavor that the third parties with whom We share the Personal
              Data comply with the rules or policies specified under this Policy
              and subsequently notify the Company if it makes a determination,
              it can no longer meet this obligation. We will take all the
              reasonable steps to prevent or stop unauthorized use or disclosure
              of the Personal Data by the third parties.
            </li>
            <li>
              Further, We may disclose information about You: (i) if we are
              required to do so by law or legal process, (ii) to law enforcement
              authorities or other government officials, (iii) to our
              subsidiaries or affiliates; (iv) to fulfill the purpose for which
              it was provided, (v) for any other purpose disclosed by Us when
              You provide the information; or (vi) when We believe disclosure is
              necessary or appropriate to prevent physical harm or financial
              loss, or in connection with an investigation of suspected or
              actual fraudulent or illegal activity. We may disclose Personal
              Data to third party rights owners, or others in the good faith
              belief that such disclosure is reasonably necessary to: (a)
              enforce Our Terms of Use and this Policy; (b) respond to claims
              that an advertisement, posting or other content violates the
              rights of a third party; (c) respond to any authority, having the
              right to receive such information under law; or (d) protect the
              rights, property or personal safety of the users or the general
              public.
            </li>
            <li>
              In the event of any change in control of the Company which may
              include proposed or actual reorganization, sale, merger, joint
              venture, assignment, amalgamation or any other type of
              acquisition, disposal or financing of all or any portion of the
              Company or of any of the Company assets, We may disclose or
              transfer Your Personal Data to such third party. Should such an
              event take place, We will endeavor to direct the transferee to use
              the Personal Data in a manner that is consistent with this Policy.
            </li>
          </ol>
          <h2>VI. TRANSFER OF PERSONAL DATA </h2>
          <p>
            We provide Services globally and We may use third parties located in
            other countries to help Us run Our business. As a result, the
            Personal Data may be transferred outside the countries where We and
            Our clients are located. The Personal Data may be transferred
            internally to Our affiliates and externally to third parties
            (including any data uploaded or provided by You, Your user or any
            other client or its user) across international borders for the
            purposes described in this Policy. This may include transfers to
            countries that may not have laws that provide the same degree of
            protection for the Personal Data as Your home country.{" "}
          </p>
          <h2>VII. DATA PROTECTION STRATEGIES </h2>
          <p>We follow several strategies to protect your data such as:</p>
          <ul>
            <li>
              <u>Data loss prevention:</u> We employ tools to ensure that Your
              data is not lost, accidentally deleted or stolen;
            </li>
            <li>
              <u>Firewalls:</u> We use firewalls to monitor and filter network
              traffic, ensuring that only authorized users are allowed to access
              or transfer data;
            </li>
            <li>
              <u>Authentication and authorization:</u> We use tools to ascertain
              and verify the credentials, assuring that user privileges are
              applied correctly; and
            </li>
            <li>
              <u>Endpoint protection:</u> We use endpoint protection software to
              protect gateways to our network.
            </li>
          </ul>
          <h2>VIII. DATA PROTECTION IMPACT ASSESSMENT </h2>
          <p>
            In the event that We change Our processes relating to data
            processing and protection, particularly through introduction of new
            technologies, We will undertake a risk assessment (“
            <b>Data Protection Impact Assessment</b>”) to analyze the risk to
            Your data. This Data Protection Impact Assessment shall take into
            account the current nature, scope, context and purpose of data
            processing under this Policy, to flag any significant changes or
            risks to Your rights. If the risk level is found to be high even
            after undertaking standard risk mitigating measures, Our Data
            Protection Officer (“<b>DPO</b>”) will contact appropriate statutory
            authority for consultation.
          </p>
          <h2>IX. THIRD PARTY LINKS </h2>
          <p>
            Our Platform may contain links to or display links to the websites
            or mobile applications that are owned or operated by the third
            parties which may not be governed by this Policy. Such websites
            links are independent from the Company, and the Company has no
            control over the content on that website, even if the Company
            provides information or Services to the owner of that website. The
            Company is not making any representations about, endorsing, or
            accepting any responsibility for the content or the use of such
            website. Further, We are not responsible or liable for any damage or
            loss related to the use of any third party website. We encourage
            users to review the privacy and data protection policy of each
            website visited before accessing or disclosing any Personal Data.
          </p>
          <h2>X. SECURITY MEASURES</h2>
          <ol>
            <li>
              We use reasonable security measures to help protect against the
              loss, misuse and alteration of the Personal Data under Our
              control. However, despite our best efforts, security cannot be
              absolutely guaranteed against all threats. To the best of Our
              ability, the access to Your Personal Data is limited to those who
              have a need to know. Those individuals who have access to the
              Personal Data are required to maintain the confidentiality of such
              data. In addition, please note that emails, messages sent via Your
              web browser, and other similar means of communication with other
              users, are not encrypted. Therefore, while We strive to protect
              Your information, We cannot guarantee its security. In case You
              require to share any Personal Data with the Company, please share
              it with Us at{" "}
              <u>
                <a href="mailto: support@canonic.dev">support@canonic.dev</a>
              </u>
              . You understand and acknowledge that, We cannot ensure the
              security of any information received through such channels.{" "}
            </li>
            <li>
              Please also be aware that We may use third-party cloud service
              providers that provide hosting, data storage and other services
              pursuant to standard terms and conditions that may be
              non-negotiable. These service providers have informed Us or the
              general public that they apply security measures they consider
              adequate for the protection of information within their system, or
              they have a general reputation for applying such measures.
              However, We will not be liable (to the fullest extent permitted by
              law) for any damages that may result from the misuse of any
              information, including Personal Data, by these companies.{" "}
            </li>
          </ol>
          <h2>XI. YOUR RIGHTS WITH RESPECT TO PERSONAL DATA</h2>
          <p>
            1. In the event, We process Your Personal Data, You may avail
            Yourself the rights explained below. Please note that prior to
            fulfilling Your request in relation to the rights provided below, we
            may verify Your identity associated with Our Platform. In case We
            are unable to verify Your identity, We reserve the right to deny a
            request. If You authorize someone to make a request on Your behalf,
            We may also deny Your request if We are unable to verify with You
            that the individual making the request is authorized to act on Your
            behalf.
          </p>
          <p>
            2. In the event, We are processing Your Personal Data on behalf of
            another entity or are acting as a data processor, We will forward
            Your requests, inquiries or claims concerning these processing
            activities to the respective data controller the necessary action.
          </p>
          <p>3. Your Rights are as follows:</p>
          <ul>
            <li>
              <u>
                The Rights of Access to and Portability of Your Personal Data:
              </u>
              You may request access to Your Personal Data by submitting a
              request at{" "}
              <u>
                <a href="mailto: support@canonic.dev">support@canonic.dev</a>
              </u>{" "}
              If required by law, upon request, We will grant You reasonable
              access to Your Personal Data We will provide Your Personal Data to
              You in a portable format to allow it to be transmitted to third
              parties.
            </li>
            <li>
              <u>The Right to Delete Your Personal Data:</u> Typically, We
              retain Your Personal Data for the period necessary to fulfil the
              purposes outlined in this Policy and to provide You the Services,
              unless a longer retention period is mandated by law. You may,
              however, request that We delete Your Personal Data by submitting a
              request at{" "}
              <u>
                <a href="mailto: support@canonic.dev">support@canonic.dev</a>
              </u>{" "}
              If required by law, We will accept such a request to delete
              information. Please note that in many situations We are required
              by applicable law to retain all, or a portion, of Your Personal
              Data to comply with Our legal obligations, resolve disputes,
              enforce our agreements, to protect against fraudulent, deceptive,
              or illegal activity, or for another one of our business purposes.
            </li>
          </ul>
          <p>
            4. Please note that in the event We identify that Our system has
            been hacked or security measures have been breached and Your
            Personal Data is disclosed, We will notify You as soon as possible
            but no later than 72 (seventy-two) hours.{" "}
          </p>
          <p>
            5. <b>Some Limitations:</b> The rights provided herein are subject
            to certain limitations as specified under applicable laws. Any
            individual requests will be completed within the time allotted by
            relevant regulations, which starts to run from the point of the
            Company confirming Your request. To the extent permissible by
            applicable law and where permitted, We may impose a charge for
            subsequent requests from the same individual which will be
            determined by the Company.
          </p>
          <p>
            6. Please note, if We delete Your Personal Data after Your request,
            it will affect Our ability to provide the Services to You and We may
            need to keep such Personal Data for a while during the shutting down
            and billing process.{" "}
          </p>

          <h2>XII. DATA PROTECTION RIGHTS UNDER CalOPPPA</h2>
          <p>
            Users based out of California also have the following additional
            rights under CalOPPA:
          </p>
          <ul>
            <li>Users can visit Our Website anonymously;</li>
            <li>
              Users will be notified of any Policy change on Our Policy page;
            </li>
            <li>
              Users can change their personal information by emailing us at{" "}
              <u>
                <a href="mailto: support@canonic.dev">support@canonic.dev</a>
              </u>
              .
            </li>
          </ul>
          <h2>XIII. CHILDERN UNDER SIXTEEN</h2>
          <p>
            We do not collect any data from children under the age of 16
            (sixteen). In the event You are under the age of 16 (sixteen), You
            must ask Your parent or guardian for permission to use the Platform.
            We attempt to delete any information so received on the Platform, if
            We learn that the Personal Data is the information of a child under
            16 (sixteen). If You believe that We might have any Personal Data
            from a child under 16 (sixteen), please contact Us at{" "}
            <u>
              <a href="mailto: support@canonic.dev">support@canonic.dev</a>
            </u>
            .
          </p>
          <h2>XIV. USE OF COOKIES</h2>
          <ol>
            <li>
              We use two types of cookies, (i) Transient Cookies and (ii)
              Persistent Cookies.
            </li>
            <li>
              Transient Cookies are automatically deleted when You close Your
              browser. This includes in particular the session cookies. These
              store a so-called session ID, with which different requests of
              Your browser can be assigned to the common session. This will
              allow Your computer to be recognized when You return to Our
              Website. Session cookies are deleted when You log out or close
              your browser.
            </li>
            <li>
              Persistent cookies are automatically deleted after a specified
              period, which may vary depending on the cookie. You can delete
              cookies at any time in the security settings of your browser.
            </li>
            <li>
              You can configure your browser settings according to Your wish
              and, for example, refuse the acceptance of third party cookies or
              all cookies. Please note that You may not be able to use all
              functions of this Website if You choose to refuse the third party
              cookies.
            </li>
            <li>
              We use cookies to identify You for follow-up visits if You have an
              account with Us. Otherwise You will have to log in again for each
              visit.
            </li>
            <li>
              The purpose of using technically required cookies is to simplify
              the use of Our Website for Your benefit. Several functionalities
              of Our Website cannot be offered without the use of cookies. For
              these functionalities it is required to re-identify the browser.
              The use of analytical cookies serves the purpose of improving the
              quality of our website and its contents. These cookies show the
              use of the Website, enabling us to optimize our offering.
            </li>
          </ol>
          <h2>XV. THIRD PARTY ANALYTICS</h2>
          <p>
            1. We may use third party web analytics services on the Platform.
            The service providers that administer these services use
            technologies such as cookies, web server logs and web beacons to
            collect information (such as IP address) and use that information to
            help Us analyse how visitors use the Platform. When You access or
            browse the Platform, a cookie banner will inform You of the use of
            these analytics technologies. They will only be used if You accept
            them or if You continue using the Platform. You may change Your
            cookie settings at any time to accept or refuse these analytics
            technologies by clicking on the cookie control tool below to adjust
            Your cookie preferences. Adjusting Your cookie preferences may
            disable certain functionality on the Platform. You consent to the
            processing of information about You by these analytics providers in
            the manner and for the purposes set out in this Policy.
          </p>
          <p>2. At present, some of the analytic partners We work with are: </p>
          <ul>
            <li>Google Analytics</li>
            <li>Hotjar</li>
          </ul>
          <p>We may update this list from time to time.</p>
          <p>
            3. For more information on these third parties, including how to opt
            out from certain data collection, please visit the sites below.
            Please be advised that if You opt out of any Services, You may not
            be able to use the full functionality of the Platform.
          </p>
          <h2>XVI. AMENDMENT TO THIS POLICY</h2>
          <p>
            We keep Our Policy under regular review and We may modify this
            Privacy and Data Protection Policy from time to time to reflect our
            current privacy and data protection practices. Any changes to the
            processing of Personal Data as described in this Policy affecting
            You will be communicated to You through an appropriate channel,
            depending on how We normally communicate with You.{" "}
          </p>
          <h2>XVII. PRIVACY AND DATA PROTECTION CONCERNS</h2>
          <p>
            If You are concerned about the handling of Your Personal Data, or if
            You have any complaints or queries related to Your Personal Data or
            Our Policy, please contact Our grievance officer mentioned below:
          </p>
          <p>Name: Simranjot Singh</p>
          <p>Email: simranjot@canonic.dev</p>
          <p>
            <em>Last updated: 26th May 2022</em>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
