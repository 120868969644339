/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import OG from "@src/icons/og/og.png"

function SEO({
  description,
  lang,
  meta,
  title,
  url,
  ogImage,
  ogType,
  ogTitle,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const ref = searchParams.get("ref")
    const refId = searchParams.get("refId")
    if (ref) sessionStorage.setItem("ref", ref)
    if (refId) sessionStorage.setItem("refId", refId)
  }, [])

  const metaDescription = description || site.siteMetadata.description
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={
        title.includes("Canonic |") ? `%s` : `%s | ${site.siteMetadata.title}`
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "title",
          property: `og:title`,
          content: ogTitle
            ? ogTitle
            : title.includes("Canonic |")
            ? title
            : `${title} | ${site.siteMetadata.title}`,
        },
        {
          name: "image",
          property: `og:image`,
          content: ogImage || OG,
        },
        {
          property: `og:image:width`,
          content: `1200px`,
        },
        {
          property: `og:image:height`,
          content: `627px`,
        },
        {
          name: "description",
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: ogType || `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:site_name`,
          content: "Canonic",
        },
        {
          property: `fb:app_id`,
          content: "569463117074345",
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: ogTitle
            ? ogTitle
            : title.includes("Canonic |")
            ? title
            : `${title} | ${site.siteMetadata.title}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: ogImage || OG,
        },
        {
          name: `twitter:image:alt`,
          content: title,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
